import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  const { iconActive } = props
  const fill = iconActive ? '#FAFAFA' : '#DEDEDE'
  const opacity = iconActive ? '1' : '0.5'
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 1024 1024"
      fill={fill}
      vertical-align="middle"
      {...props}
    >
      <g opacity={opacity}>
        <path d="M764.16 213.333333h-512l55.466667-55.04a42.666667 42.666667 0 0 0-60.586667-60.586666l-128 128a42.666667 42.666667 0 0 0 0 60.586666l128 128a42.666667 42.666667 0 0 0 60.586667 0 42.666667 42.666667 0 0 0 0-60.586666L252.16 298.666667h512a66.56 66.56 0 0 1 67.84 65.28V469.333333a42.666667 42.666667 0 0 0 85.333333 0V363.946667A151.893333 151.893333 0 0 0 764.16 213.333333zM776.96 609.706667a42.666667 42.666667 0 0 0-60.586667 60.586666l55.466667 55.04h-512a66.56 66.56 0 0 1-67.84-65.28V554.666667a42.666667 42.666667 0 0 0-85.333333 0v105.386666A151.893333 151.893333 0 0 0 259.84 810.666667h512l-55.466667 55.04a42.666667 42.666667 0 0 0 0 60.586666 42.666667 42.666667 0 0 0 60.586667 0l128-128a42.666667 42.666667 0 0 0 0-60.586666z" />
        fill={fill}
        />
      </g>
    </Svg>
  )
}
