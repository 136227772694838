import { Field } from 'redux-form'

export interface TagPropsType {
  component: any
  title: string
  name: string
  heightAuto?: boolean
  allowNew?: boolean
  filteredItems?: TagType[]
  inputAttributes?: { [key: string]: any }
}

export interface TagType {
  id: number
  name: string
}
export const FieldTags = Field as new () => Field<TagPropsType>
