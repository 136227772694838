import { all, call, fork } from 'redux-saga/effects'
import * as S from 'domain/manager/assocSkills'

export function* skillsPage() {
  yield all([fork(getAssocSkillsList)])
}

function* getAssocSkillsList() {
  yield call(S.ensureGetAssocSkillsList)
}
