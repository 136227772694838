import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import { pageIsLoading } from 'domain/loading'
import Api from 'domain/api'
import * as Actions from 'domain/jobs/actions'
import * as M from '../env'
import * as Router from 'domain/router'
import { jobNotesSelector, candidateJobNotesSelector } from 'domain/jobs'
import { NoteType } from 'types/common'

export function* ensureUpdateJobRecruiters({
  payload
}: {
  payload: { id: number; data: { recruiterIds: number[] } }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  try {
    const { data } = yield call(Api.updateJobRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: payload.data
    })
    yield put({ type: Actions.updateJobRecruiters.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.updateJobRecruiters.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetJobNotes() {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.getJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })

    yield put({ type: Actions.getJobNotes.success, payload: I.fromJS(data.data.reverse()) })
  } catch (err) {
    yield put({
      type: Actions.getJobNotes.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePostJobNotes(props: { payload: { note: string }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const jobNotes = yield select(jobNotesSelector)

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.postJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      data: props.payload
    })
    const newNotes = [{ ...data.data, createdBy: user.toJS() }, ...jobNotes.toJS()]
    yield put({ type: Actions.getJobNotes.success, payload: I.fromJS(newNotes) })
  } catch (err) {
    yield put({
      type: Actions.addNoteToJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeleteJobNote(props: { payload: { noteId: number }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return
  const jobNotes = yield select(jobNotesSelector)

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    yield call(Api.deleteJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      jobId: id,
      noteId: props.payload.noteId
    })

    const newJobNotes = jobNotes.toJS().filter((note: NoteType) => note.id !== props.payload.noteId)
    yield put({ type: Actions.getJobNotes.success, payload: I.fromJS(newJobNotes) })
  } catch (err) {
    yield put({
      type: Actions.deleteNoteInJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureEditJobNote(props: {
  payload: { noteId: number; noteText: string }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return
  const jobNotes = yield select(jobNotesSelector)

  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  try {
    const { data } = yield call(Api.putJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      jobId: id,
      noteId: props.payload.noteId,
      data: { note: props.payload.noteText }
    })

    const newJobNotes = jobNotes
      .toJS()
      .map((item: NoteType) => (item.id === props.payload.noteId ? data.data : item))

    yield put({ type: Actions.getJobNotes.success, payload: I.fromJS(newJobNotes) })
  } catch (err) {
    yield put({
      type: Actions.editNoteInJob.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetCandidateJobNotes() {
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { candidateId, id, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  if (!rightJobId) return

  try {
    yield put({ type: pageIsLoading, payload: true, source: 'ensureGetCandidateJobNotes()::' })
    const { data } = yield call(Api.getCandidateJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId
    })
    yield put({ type: Actions.getCandidateJobNotes.success, payload: I.fromJS(data.data.notes) })
  } catch (err) {
    yield put({
      type: Actions.getCandidateJobNotes.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePostCandidateJobNotes(props: { payload: { note: string }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const candidateJobNotes = yield select(candidateJobNotesSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    const { data } = yield call(Api.postCandidateJobNotes, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId,
      data: props.payload
    })
    const newNotes = [{ ...data.data, createdBy: user.toJS() }, ...candidateJobNotes.toJS()]
    yield put({ type: Actions.getCandidateJobNotes.success, payload: I.fromJS(newNotes) })
  } catch (err) {
    yield put({
      type: Actions.addCandidateJobNote.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeleteCandidateJobNote(props: {
  payload: { noteId: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return

  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    yield call(Api.deleteCandidateJobNote, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId,
      noteId: props.payload.noteId
    })

    const candidateJobNotes = yield select(candidateJobNotesSelector)
    const newCandidateJobNotes = candidateJobNotes
      .toJS()
      .filter((note: NoteType) => note.id !== props.payload.noteId)

    yield put({
      type: Actions.getCandidateJobNotes.success,
      payload: I.fromJS(newCandidateJobNotes)
    })
  } catch (err) {
    yield put({
      type: Actions.deleteCandidateJobNote.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureEditCandidateJobNote(props: {
  payload: { noteId: number; noteText: string }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)

  if (!headers) return
  const user = yield select(M.userSelector)
  const userRole = user.get('role')

  const {
    params: { id, candidateId, jobsId }
  } = yield select(Router.matchRoutes)

  const rightCandidateId = userRole === 'recruiter' ? id : candidateId
  const rightJobId = userRole === 'recruiter' ? jobsId : id

  try {
    const { data } = yield call(Api.editCandidateJobNote, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: rightCandidateId,
      jobId: rightJobId,
      noteId: props.payload.noteId,
      data: { note: props.payload.noteText }
    })

    const candidateJobNotes = yield select(candidateJobNotesSelector)
    const newCandidateJobNotes = candidateJobNotes
      .toJS()
      .map((item: NoteType) => (item.id === props.payload.noteId ? data.data : item))

    yield put({
      type: Actions.getCandidateJobNotes.success,
      payload: I.fromJS(newCandidateJobNotes)
    })
  } catch (err) {
    yield put({
      type: Actions.editCandidateJobNote.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureExtractSkills(props: { payload: { description: string }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.extractSkills, {
      headers: { Authorization: `Bearer ${headers}` },
      data: props.payload
    })
    yield put({ type: Actions.extractSkills.success, payload: data })
  } catch (err) {
    yield put({
      type: Actions.extractSkills.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
