import { Styles } from 'react-jss'

export const sheet: Styles = {
  btn: {
    fontFamily: 'Roboto, sans-serif',
    textDecoration: 'none',
    border: 'none',
    maxWidth: '100%',
    background: 'none',
    position: 'relative',
    fontStyle: 'inherit',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#FAFAFA',
    boxSizing: 'border-box',
    padding: '12px 35px 11px',
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: 'inherit',
    '&:first-child': { marginLeft: 0 },
    '&:last-child': { marginRight: 0 },
    '&:active, &:focus': { outline: 'none' },
    marginRight: '3%',
    whiteSpace: 'nowrap',
    '@media (max-width: 600px)': {
      fontSize: 12
    }
  },
  link: {
    lineHeight: '21px',
    fontSize: 17,
    letterSpacing: '0.01em',
    textTransform: 'none'
  },
  withIcon: {
    fontWeight: '500',
    lineHeight: '18px',
    fontSize: 13,
    letterSpacing: '0.03em',
    textTransform: 'capitalize',
    color: '#080910',
    margin: '0 10px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 10
    }
  },
  menu: {
    fontWeight: 'normal',
    lineHeight: '22px',
    fontSize: 15,
    letterSpacing: '0.01em',
    color: '#E6E6E6',
    padding: '15px 10px',
    width: '100%',
    '&:hover, &:focus': {
      color: '#E6E6E6',
      textDecoration: 'none'
    }
  },
  authLink: {
    lineHeight: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 13,
    letterSpacing: '0.03em',
    textTransform: 'capitalize',
    color: '#888888',
    whiteSpace: 'nowrap'
  },
  auth: {
    backgroundColor: '#286EFA'
  },
  default: {
    padding: '18px 44px 17px',
    backgroundColor: '#286EFA',
    width: '15rem'
  },
  primary: {
    padding: '18px 44px 17px',
    backgroundColor: '#FAFAFA',
    color: '#080910',
    width: '15rem'
  },
  secondary: {
    border: '2px solid #286EFA'
  },
  disabled: {
    padding: '18px 44px 17px',
    backgroundColor: 'accent',
    color: 'grey',
    cursor: 'not-allowed',
    '& .line': {
      backgroundColor: 'grey'
    },
    '&:hover': {
      '& .line': {
        transform: 'none'
      }
    }
  },
  submit: {
    backgroundColor: '#286EFA',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#FAFAFA'
  },
  submitDisabled: {
    backgroundColor: '#88888888',
    padding: 12
  },
  submitUnclick: {
    backgroundColor: '#88888888',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#FAFAFA',
    pointerEvents: 'none'
  },
  blueButton: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '0.03em',
    color: '#286EFA',
    padding: 0,
    lineHeight: '18px'
  },
  unsaved: {
    fontWeight: '500',
    lineHeight: '18px',
    fontSize: 13,
    letterSpacing: '0.03em',
    color: '#E14A4A',
    background: '#FCE2E2',
    border: '1px solid #E14A4A',
    borderRadius: '1px',
    textTransform: 'none',
    padding: 8,
    height: 'fit-content',
    marginLeft: 25
  },
  saved: {
    fontWeight: '500',
    lineHeight: '18px',
    fontSize: 13,
    letterSpacing: '0.03em',
    color: '#00AE23',
    textTransform: 'capitalize',
    padding: 8,
    marginLeft: 25
  },
  redButton: {
    backgroundColor: '#E14A4A',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#FAFAFA'
  },
  redButtonDelete: {
    paddingRight: 16,
    '&:before': {
      content: ` url("${require('components/svg/closeWhite.svg')}")`,
      position: 'absolute',
      left: '10%',
      top: '35%'
    }
  },
  check: {
    paddingRight: 16,
    '&:before': {
      content: ` url("${require('components/svg/check.svg')}")`,
      position: 'absolute',
      left: '10%',
      top: '35%'
    }
  },
  withdraw: {
    padding: '12px 15px 11px 35px',
    '&:before': {
      content: ` url("${require('components/svg/download.svg')}")`,
      position: 'absolute',
      left: '10%',
      top: '35%'
    }
  },
  withdrawDisabled: {
    background: '#E6E6E6'
  },
  cancel: {
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#E14A4A',
    '&:before': {
      content: ` url("${require('components/svg/close.svg')}")`,
      position: 'absolute',
      left: '10%',
      top: '35%'
    }
  },
  delete: {
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#FAFAFA',
    background: '#989FA6',
    paddingLeft: 15,
    paddingRight: 15
  },
  activeTab: {
    background: '#F0F0F0',
    color: '#286EFA',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 143,
    border: '1px solid transparent',
    '@media (max-width: 600px)': {
      fontSize: 12
    }
  },
  tab: {
    border: '1px solid #E6E6E6',
    boxSizing: 'border-box',
    color: '#888888',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    background: 'transparent',
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 143,
    '@media (max-width: 600px)': {
      fontSize: 12
    }
  },
  add: {
    backgroundColor: '#286EFA',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#FAFAFA',
    paddingLeft: 17,
    paddingRight: 14,
    marginLeft: 60,
    '@media (max-width: 600px)': {
      fontSize: 12
    }
  },
  reopen: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '19px',
    letterSpacing: '0.01em',
    color: '#989FA6',
    borderLeft: '1px solid #989FA6',
    padding: '0 0 0 8px',
    width: 'max-content'
  },
  holdJob: {
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#080910',
    '&:before': {
      content: ` url("${require('components/svg/pause.svg')}")`,
      position: 'absolute',
      left: '10%',
      top: '35%'
    }
  },
  closeJob: {
    background: '#E6E6E6',
    padding: '12px 12px 11px 36px',
    '&:before': {
      content: ` url("${require('components/svg/closeBlack.svg')}")`,
      position: 'absolute',
      left: '10%',
      top: '35%'
    }
  },
  back: {
    position: 'relative',
    fontWeight: '500',
    fontSize: 13,
    lineHeight: '18px',
    letterSpacing: '0.03em',
    textTransform: 'capitalize',
    color: '#989FA6',
    padding: '0 0 0 15px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      left: 0,
      borderTop: '6px solid transparent',
      borderBottom: '6px solid transparent',
      borderRight: '6px solid #989FA6'
    }
  },
  accept: {
    padding: '0 0 0 20px',
    fontWeight: '500',
    lineHeight: '18px',
    fontSize: 13,
    letterSpacing: '0.03em',
    color: '#286EFA',
    textTransform: 'capitalize',
    marginLeft: 25,
    '&:before': {
      content: ` url("${require('components/svg/checkBlue.svg')}")`,
      position: 'absolute',
      left: 0,
      top: '15%'
    }
  },
  decline: {
    fontWeight: '500',
    lineHeight: '18px',
    fontSize: 13,
    letterSpacing: '0.03em',
    color: '#E14A4A',
    textTransform: 'capitalize',
    marginLeft: 25,
    padding: '0 0 0 20px',
    '&:before': {
      content: ` url("${require('components/svg/close.svg')}")`,
      position: 'absolute',
      left: 0,
      top: '15%'
    }
  },
  dashboard: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 17,
    letterSpacing: '0.01em',
    color: '#286EFA',
    padding: 0,
    lineHeight: '21px'
  },
  tabPeriod: {
    width: 75,
    padding: '8px 0 7px'
  },
  disabledTabPeriod: {
    color: '#E6E6E6'
  },
  greenButton: {
    background: '#00AE23',
    fontStyle: 'normal',
    lineHeight: '16px',
    color: '#F0F0F0'
  },
  greenButtonUp: {
    background: '#00AE23',
    fontStyle: 'normal',
    lineHeight: '16px',
    color: '#F0F0F0',
    padding: '3px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&:before, &:after': {
      content: '"\\2B06"',
      fontSize: 14,
      color: '#F0F0F0',
      position: 'absolute'
    },
    '&:before': {
      left: '10px'
    },
    '&:after': {
      right: '10px'
    }
  },
  notificationLink: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: '#286EFA',
    padding: 0
  },
  notificationLinkTitle: {
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    padding: 0,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '18px',
    color: '#080910'
  },
  dateTimeEvent: {
    background: '#FAFAFA',
    border: '1px solid #E6E6E6',
    boxSizing: 'border-box',
    padding: '13px 10px 7px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: '0.01em',
    textTransform: 'capitalize',
    color: '#080910',
    '&:focus': {
      background: '#286EFA',
      border: '1px solid transparent',
      color: '#FAFAFA'
    }
  },
  dangerous: { color: '#000', padding: '5px 3px', fontSize: '13px', lineHeight: '15px' },
  '@media (min-width: 992px) and (max-width: 1199.98px)': {
    link: {
      padding: '6px 15px 5px'
    }
  },
  '@media (min-width: 768px) and (max-width: 991.98px)': {
    btn: {
      // padding: '12px 12px 11px',
      marginRight: '2%'
    },
    link: {
      padding: '6px 6px 5px'
    }
  }
}
