import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import { error403, pageIsLoading } from 'domain/loading'
import { assocSkillsList } from './actions'

export interface PostAssocSkillType {
  tagId: number
  associatedTagIds: number[]
}

export interface DeleteAssocSkillItemType {
  tagId?: number
  associatedTagId: number
}

export function* ensureGetAssocSkillsList() {
  try {
    const userToken = yield select(M.userToken)
    if (!userToken) return

    const {
      data: { data }
    } = yield call(Api.getAssocSkillsList, {
      headers: { Authorization: `Bearer ${userToken}` }
    })
    yield put({ type: Actions.assocSkillsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.assocSkillsList.failure,
      err
    })
  }
}

export function* ensureGetAssocSkillData() {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    path,
    params: { id }
  } = yield select(Router.matchRoutes)
  if (id === 'new') {
    yield put({ type: Actions.assocSkillData.success, payload: I.fromJS({}) })
    return
  }
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getAssocSkillData, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.assocSkillData.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({ type: Actions.assocSkillData.success, payload: I.fromJS({}) })
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.assocSkillData.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePostAssocSkill({ payload }: { payload: PostAssocSkillType; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.postAssocSkillData, {
      headers: { Authorization: `Bearer ${headers}` },
      data: payload
    })
    yield put({ type: Actions.postAssocSkillData.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.postAssocSkillData.FAILURE,
      err
    })
  }
}

export function* ensureDeleteAssocSkillItem({
  payload
}: {
  payload: DeleteAssocSkillItemType
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  const { tagId, associatedTagId } = payload
  try {
    const {
      data: { data }
    } = yield call(Api.deleteAssocSkillItem, {
      headers: { Authorization: `Bearer ${headers}` },
      tagId: tagId || id,
      associatedTagId
    })
    yield put({ type: Actions.deleteAssocSkillItem.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.deleteAssocSkillItem.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

//
// export interface Params {
//   page?: number
//   order?: Sort
//   order_by?: string | null
//   status?: string
//   search?: string
//   ownership?: string
//   clientId?: string
//   recruiterId?: string
// }
//
// export function* ensureGetJobs(props: { payload: Params; type: string }) {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   if (props.payload.page === 1) {
//     yield put({ type: skeleton, payload: true })
//     yield put({ type: pageIsLoading, payload: true })
//   }
//   try {
//     const { data } = yield call(Api.getJobs, {
//       headers: { Authorization: `Bearer ${headers}` },
//       params: props.payload
//     })
//     yield put({ type: Actions.jobsList.success, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({
//       type: Actions.jobsList.failure,
//       err
//     })
//   } finally {
//     yield put({ type: skeleton, payload: false })
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
//
// export function* ensureGetJob() {
//   yield put({ type: error403, payload: false })
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   const {
//     path,
//     params: { id }
//   } = yield select(Router.matchRoutes)
//   if (id === 'new') {
//     yield put({ type: Actions.jobData.success, payload: I.fromJS({}) })
//     return
//   }
//   try {
//     yield put({ type: pageIsLoading, payload: true })
//     const {
//       data: { data }
//     } = yield call(Api.getJob, {
//       headers: { Authorization: `Bearer ${headers}` },
//       id
//     })
//     data.isCloned = path.includes('/clone')
//     yield put({ type: Actions.jobData.success, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({ type: Actions.jobData.success, payload: I.fromJS({}) })
//     const { statusCode } = err.response.data
//     if (statusCode === 403 || statusCode === 404) {
//       yield put({ type: error403, payload: true })
//     }
//     yield put({
//       type: Actions.jobData.failure,
//       err
//     })
//   } finally {
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
//
// export function* ensurePutJob({ payload }: { payload: FormValuesJobForManager; type: string }) {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   yield put({ type: pageIsLoading, payload: true })
//   const {
//     params: { id }
//   } = yield select(Router.matchRoutes)
//   try {
//     const employmentTypeConst = yield select(employmentTypeSelector)
//     const {
//       data: { data }
//     } = yield call(Api.putJob, {
//       headers: { Authorization: `Bearer ${headers}` },
//       id,
//       data: transformJob(payload, employmentTypeConst)
//     })
//     yield put({ type: Actions.putJob.SUCCESS, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({
//       type: Actions.putJob.FAILURE,
//       err
//     })
//   } finally {
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
//

//
// export function* ensureHoldJob() {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   yield put({ type: pageIsLoading, payload: true })
//   const {
//     params: { id }
//   } = yield select(Router.matchRoutes)
//   try {
//     const {
//       data: { data }
//     } = yield call(Api.toggleHoldJob, {
//       headers: { Authorization: `Bearer ${headers}` },
//       id
//     })
//     yield put({ type: Actions.holdJob.success, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({
//       type: Actions.holdJob.failure,
//       err
//     })
//   } finally {
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
//
// export function* ensureCloseJob({
//                                   payload
//                                 }: {
//   payload: { recruiterId: number; candidateId: number }
//   type: string
// }) {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   yield put({ type: pageIsLoading, payload: true })
//   const {
//     params: { id }
//   } = yield select(Router.matchRoutes)
//   try {
//     const {
//       data: { data }
//     } = yield call(Api.closeJob, {
//       headers: { Authorization: `Bearer ${headers}` },
//       id,
//       data: payload
//     })
//     yield put({ type: Actions.closeJob.SUCCESS, payload: I.fromJS(data) })
//     yield call(ensureGetJob)
//   } catch (err) {
//     yield put({
//       type: Actions.closeJob.FAILURE,
//       err
//     })
//   } finally {
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
//
// export function* ensureCandidatesForCloseJob() {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   const {
//     params: { id }
//   } = yield select(Router.matchRoutes)
//   try {
//     yield put({ type: modalIsLoading, payload: true })
//     const {
//       data: { data }
//     } = yield call(Api.candidatesForCloseJob, {
//       headers: { Authorization: `Bearer ${headers}` },
//       id
//     })
//     yield put({ type: Actions.candidatesForCloseJob.success, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({
//       type: Actions.candidatesForCloseJob.failure,
//       err
//     })
//   } finally {
//     yield put({ type: modalIsLoading, payload: false })
//   }
// }
//
// export function* ensureRecruitersForCloseJob({ payload }: { payload: number; type: string }) {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   const {
//     params: { id }
//   } = yield select(Router.matchRoutes)
//   try {
//     yield put({ type: modalIsLoading, payload: true })
//     const {
//       data: { data }
//     } = yield call(Api.recruitersForCloseJob, {
//       headers: { Authorization: `Bearer ${headers}` },
//       id,
//       candidateId: payload
//     })
//     yield put({ type: Actions.recruitersForCloseJob.success, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({
//       type: Actions.recruitersForCloseJob.failure,
//       err
//     })
//   } finally {
//     yield put({ type: modalIsLoading, payload: false })
//   }
// }
//
// export function* ensureGetAllJobs(props?: { payload?: Params; type: string }) {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//   if (!props || !props.payload || props.payload.page === 1) {
//     yield put({ type: skeleton, payload: true })
//     yield put({ type: pageIsLoading, payload: true })
//   }
//   const params = (props && props.payload) || { page: 1 }
//   try {
//     const { data } = yield call(Api.getAllJobs, {
//       headers: { Authorization: `Bearer ${headers}` },
//       params
//     })
//     yield put({ type: Actions.getAllJobs.success, payload: I.fromJS(data) })
//   } catch (err) {
//     yield put({
//       type: Actions.jobsList.failure,
//       err
//     })
//   } finally {
//     yield put({ type: skeleton, payload: false })
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
//
// export function* ensureGetManagersList() {
//   const headers = yield select(M.userToken)
//   if (!headers) return
//
//   try {
//     yield put({ type: pageIsLoading, payload: true })
//     const {
//       data: { data }
//     } = yield call(Api.getManagersList, {
//       headers: { Authorization: `Bearer ${headers}` }
//     })
//
//     const users =
//       data.users &&
//       data.users.sort(
//         (a: { firstName: string; lastName: string }, b: { firstName: string; lastName: string }) =>
//           `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`)
//       )
//     yield put({
//       type: Actions.getManagersList.success,
//       payload: I.fromJS(users || [])
//     })
//   } catch (err) {
//     yield put({
//       type: Actions.jobsList.failure,
//       err
//     })
//   } finally {
//     yield put({ type: pageIsLoading, payload: false })
//   }
// }
