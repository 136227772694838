import { all, call, fork, take, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { pageIsLoading } from 'domain/loading'
import * as S from 'domain/manager/assocSkills'

export function* skillEdit() {
  yield all([fork(getAssocSkillData), fork(postAssocSkillData), fork(deleteAssocSkillItem)])
}

function* getAssocSkillData() {
  yield call(S.ensureGetAssocSkillData)
  yield takeLatest(S.assocSkillData.type, S.ensureGetAssocSkillData)
  yield take(S.assocSkillData.success)
}

function* postAssocSkillData() {
  yield takeLatest(S.postAssocSkillData.TRIGGER, S.ensurePostAssocSkill)
  yield take(S.postAssocSkillData.SUCCESS)
}

function* deleteAssocSkillItem() {
  yield takeLatest(S.deleteAssocSkillItem.TRIGGER, S.ensureDeleteAssocSkillItem)
  yield take(S.deleteAssocSkillItem.SUCCESS)
}
